import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { FeaturedPlace } from "../types";
import { populateCellsWithFeaturedPlace } from "../utils";

export const featuredPlaceCallbacks: EntityCallbacks = {
    onPreSave: ({
        schema,
        path,
        entityId,
        values,
        status,
        context
    }) => {
        if (!values.about.es) values.about.es = "";
        if (!values.overview.es) values.overview.es = "";
        // check if the boundary_geojson is a json
        let obj = null;
        try {
            obj = JSON.parse(values.boundary_geojson);
        } catch (error) {
            throw Error("Data is not geojson.");
        }
        // check if the json is a valid geojson
        if(!(Array.isArray(obj.features) && obj.features.length > 0 && ('geometry' in obj.features[0]) && obj.features[0].geometry.coordinates.length > 0)){
          throw Error("The geojson has not the required structure.");
        }
        return values
    },
    onSaveSuccess: async (props) => {
        console.log("onSaveSuccess props", props);
        const prev = props.previousValues!
        const actual = props.values;
        const featuredPlaceId = props.entityId;
        console.log("calling populateCellsWithFeaturedPlace")
        console.log(featuredPlaceId, props.status, prev, actual)
        populateCellsWithFeaturedPlace(featuredPlaceId, props.status, prev, actual)
    },
    onPreDelete: (props) => {
        console.log("onPreDelete props", props);
    },
    onDelete: (props) => {
        console.log("onDelete props", props);
        const featuredPlaceId = props.entityId;
        const action = "delete"
        populateCellsWithFeaturedPlace(featuredPlaceId, action, {}, props.entity)
    }

};

export const featuredPlaceSchema = buildSchema<FeaturedPlace>({
    name: "Featured Place",
    customId: true,
    properties: {
        name: {
            title: "Name",
            description: "Name of the featured place",
            validation: { required: true },
            dataType: "string"
        },
        overview: {
            title: "Overview",
            dataType: "map",
            description: "Short description about the featured place",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true, max: 200 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 200 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        about: {
            title: "About",
            dataType: "map",
            description: "Long description about the featured place",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true, max: 2000 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 2000 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        boundary_geojson: {
            title: "Boundary Geojson",
            dataType: "string",
            description: "Place's boundary (geojson). * It must be generated in https://geojson.io/. * Copy the entire json. *",
            validation: {
                required: true,
            },
            config: {
                multiline: true
            }
        },
        data_sources: {
            title: "Data sources",
            dataType: "array",
            description: "Data sources for the featured place",
            of: {
                title: "Source",
                dataType: "map",
                properties: {
                    text: {
                        dataType: "string",
                        title: "Source description",
                    },
                    url: {
                        dataType: "string",
                        title: "Source URL",
                        config: { url: true },
                        validation: { url: true },
                    },
                },
            },
        },
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        }
    }
});
