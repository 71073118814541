import { getFunctions, httpsCallable } from "firebase/functions";


export const invitationCodeGen = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
}

export const generateThumbnails = (previousValue: { [x: string]: any; }, actualValue: { [x: string]: any; }, origField: string, newField: string, newPath: string) => {

  if (origField in actualValue && (!previousValue || previousValue[origField] !== actualValue[origField])) {
    console.log('Generating thumbnail', newPath, newField)
    const apiGateway = httpsCallable(getFunctions(), 'apiGateway');
    const operation = {
      operation: "sync-resize-images_0-1-4",
      params: {
        'uri': actualValue[origField],
        'doc_paths': [{
          'path': newPath,
          'field': newField
        }]
      }
    }
    return apiGateway(operation)
  }
}

export const populateCellsWithFeaturedPlace = (featuredPlaceId: string | undefined, action: string, previousValue: { [x: string]: any; }, actualValue: { [x: string]: any; }) => {
  console.log('Generating FeaturedProject cells', previousValue, actualValue)
  const operation_name = "sync-set-featured-place-in-cells";

  const apiGateway = httpsCallable(getFunctions(), 'apiGateway');

  const operation = {
    operation: operation_name,
    version: '0.1.0',
    params: {
      'params': {
        'action': action, // new | existing | delete | copy
        'featured_place_id': featuredPlaceId
      }
    }
  }
  console.log("calling cloud function", operation)
  return apiGateway(operation)
}

export const sendAnnouncementToUsers = (announcementId: string | undefined, previousValue: { [x: string]: any; }, actualValue: { [x: string]: any; }) => {
  console.log('sendAnnouncementToUsers', previousValue, actualValue)
  const operation_name = "sync-add-announcement-to-user";

  const apiGateway = httpsCallable(getFunctions(), 'apiGateway');

  const operation = {
    operation: operation_name,
    version: '0.1.0',
    params: {
      'params': {
        'user_emails': actualValue.user_emails,
        'announcement_id': announcementId
      }
    }
  }
  console.log("operation", operation);
  return apiGateway(operation)
}

export const sendCouponToUsers = (couponId: string | undefined, previousValue: { [x: string]: any; }, actualValue: { [x: string]: any; }) => {
  console.log('sendCouponToUsers', previousValue, actualValue)
  const operation_name = "sync-add-coupon-to-user-with-task";

  const apiGateway = httpsCallable(getFunctions(), 'apiGateway');

  const operation = {
    operation: operation_name,
    version: '0.1.0',
    params: {
      'params': {
        'user_emails': actualValue.user_emails,
        'coupon_id': couponId
      }
    }
  }
  console.log("operation", operation);
  return apiGateway(operation)
}
