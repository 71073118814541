import React, { useState } from "react";
import { Button } from "@mui/material";
import { Entity, SelectionController, useSnackbarController } from "@camberi/firecms";
import { getFunctions, httpsCallable } from "firebase/functions";

export const InvitationRequestActionSync = ({ selectionController }: {
    selectionController: SelectionController
}) => {
    const snackbarContext = useSnackbarController();
    const { selectedEntities } = selectionController;
    const [busy, setBusy] = useState(false);
    const functions = getFunctions();
    const apiGateway = httpsCallable(functions, 'apiGateway');


    const sendInvitationToMailchimp  = (entity: Entity<any>) => {
      const operation = {
        operation: "send-user-to-mailchimp",
        params: entity.values
      }
      operation['params']['invitation_request_id'] = entity.id
      return apiGateway(operation)
    }

    const processEntities = async (entities: Entity<any>[]) => {
      if (entities.length === 0) {
        snackbarContext.open({type: "warning", message: `Please, select some invitations to sync`});
        return
      }

      try {
        setBusy(true)

        for (let i in selectedEntities) {
          const entity = selectedEntities[i]
          await sendInvitationToMailchimp(entity)
        }
        
        snackbarContext.open({type: "success", message: `${entities.length} invitations successfully synced`});
      } catch(e) {
        snackbarContext.open({type: "error",message: `Error syncing with mailchimp: ${(e as Error).message}`});
      } finally {
        setBusy(false)
      }
    }

    const onClickSync = async(e: React.MouseEvent) => {
      const entities = selectionController.selectedEntities
      await processEntities(entities) 
    };

    
    return (
        <>
          <Button onClick={onClickSync} color="primary" disabled={busy} title="Sync invitations with Mailchimp">
            sync
          </Button>
        </>  
    );

}
