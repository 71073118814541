import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { Species } from "../types";

export const speciesCallbacks: EntityCallbacks = {
    onPreSave: (props) => {
        console.log("onPreSave");
        if (!props.values.about.es) props.values.about.es = "";
        if (!props.values.overview.es) props.values.overview.es = "";
        props.values.common_name.forEach((item: any, index: number): void => {
            if (!item.es) props.values.common_name[index].es = "";
        });
        return props.values;
    },

    onSaveSuccess: async (props) => {
        console.log("onSaveSuccess", props);
    },
};

export const speciesSchema = buildSchema<Species>({
    name: "Species",
    customId: true,
    properties: {
        scientific_name: {
            title: "Scientific Name",
            description: "Scientific name of the specie.",
            validation: { required: true },
            dataType: "string"
        },
        kingdom: {
            title: "Kingdom",
            description: "kingdom to which the specie belongs.",
            dataType: "string",
            validation: { required: true },
            config: {
                enumValues: {
                    Fungi: { label: "Fungi" },
                    Plantae: { label: "Plantae" },
                    Animalia: { label: "Animalia" },
                }
            }
        },
        common_name: {
            title: "Common Name",
            description: "Common name of the species. Could be more than one.",
            validation: { required: false },
            dataType: "array",
            of: {
                title: "Common name translations",
                dataType: "map",
                properties: {
                    en: {
                        dataType: "string",
                        validation: { required: true },
                        title: "EN",
                    },
                    es: {
                        dataType: "string",
                        title: "ES"
                    }
                }
            }
        },
        overview: {
            title: "Overview",
            dataType: "map",
            description: "Short description about the Specie",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true, max: 1000 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 1000 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        about: {
            title: "About",
            dataType: "map",
            description: "Long description about the Specie",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true, max: 5000 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 5000 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        conservation_status: {
            title: "Conservation Status",
            description: "Conservation status of the species.",
            dataType: "string",
            config: {
                enumValues: {
                    EX: { label: "Extinct" },
                    EW: { label: "Extinct in the wild" },
                    CR: { label: "Critically endangered" },
                    EN: { label: "Endangered" },
                    VU: { label: "Vulnerable" },
                    NT: { label: "Near threatened" },
                    LC: { label: "Least concern" },
                    DD: { label: "Data deficient" },
                    NE: { label: "Not evaluated" },
                }
            }
        },
        habitat: {
            title: "Habitat",
            description: "Habitat where to find the species.",
            dataType: "string",
            config: {
                enumValues: {
                    "Forest": { label: "Forest" },
                    "Savanna": { label: "Savanna" },
                    "Shrubland": { label: "Shrubland" },
                    "Grassland": { label: "Grassland" },
                    "Wetlands": { label: "Wetlands" },
                    "Rocky Areas": { label: "Rocky Areas" },
                    "Caves & Subterranean Habitats": { label: "Caves & Subterranean Habitats" },
                    "Desert": { label: "Desert" },
                    "Marine Neritic": { label: "Marine Neritic" },
                    "Marine Oceanic": { label: "Marine Oceanic" },
                    "Marine Deep Ocean Floor": { label: "Marine Deep Ocean Floor" },
                    "Marine Intertidal": { label: "Marine Intertidal" },
                    "Marine Coastal/Supratidal": { label: "Marine Coastal/Supratidal" },
                    "Artificial - Terrestrial": { label: "Artificial - Terrestrial" },
                    "Artificial - Aquatic": { label: "Artificial - Aquatic" },
                    "Introduced Vegetation": { label: "Introduced Vegetation" },
                    "Other": { label: "Other" },
                    "Unknown": { label: "Unknown" }
                }
            }
        },
        data_sources: {
            title: "Data sources",
            dataType: "array",
            description: "Data sources for the project",
            of: {
                title: "Source",
                dataType: "map",
                properties: {
                    text: {
                        validation: { required: true },
                        dataType: "string",
                        title: "Source description",
                    },
                    url: {
                        dataType: "string",
                        title: "Source URL",
                        config: { url: true },
                        validation: { url: true, required: true },
                    },
                },
            },
        },
        featured: {
            title: "Featured",
            description: "Represent if this species could appear in explore or not.",
            dataType: "boolean"
        },
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        }
    }
});
