import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { Media } from "../types";
import mime from "mime-types";
import { generateThumbnails } from "../utils";

export const mediaCallbacks: EntityCallbacks = {
  onPreSave: ({
    schema,
    path,
    entityId,
    values,
    status,
    context
  }) => {
    console.log("onPreSave", path, entityId, values, context);
    if (!values.name.es) values.name.es = "";
    if (!values.description.es) values.description.es = "";
    if ('uri' in values) {
      if (values.mime_type == null) {
        const url = new URL(values.uri);
        values['mime_type'] = mime.lookup(url.pathname);
      }
    }

    if (values.timestamp == null) {
      values['timestamp'] = new Date();
    }

    return values
  },
  onSaveSuccess: async (props) => {
    const prev = props.previousValues!
    const actual = props.values
    const collection = props.path.split('/')[0]
    const collectionId = props.path.split('/')[1]
    await generateThumbnails(prev, actual, 'uri', 'src', collection + '/' + collectionId + '/Media/' + props.entityId)
    console.log("onSaveSuccess (Media)", props);
  }
};

export const mediaSchema = buildSchema<Media>({
  name: "Media",
  customId: false,
  properties: {
    name: {
      title: "Name",
      description: "Name of media",
      dataType: "map",
      properties: {
        en: {
          title: "EN",
          dataType: "string",
          validation: { required: true },
        },
        es: {
          title: "ES",
          dataType: "string"
        }
      }
    },
    description: {
      title: "Description",
      description: "Short description of the image.",
      dataType: "map",
      properties: {
        en: {
          title: "EN",
          dataType: "string",
          validation: { required: true },
          config: {
            multiline: true
          }
        },
        es: {
          title: "ES",
          dataType: "string",
          config: {
            multiline: true
          }
        }
      }
    },
    uri: ({ entityId }) => ({
      title: "Media URI",
      description: "Media file",
      validation: { required: false },
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          fileName: (context) => {
            const re = /(?:\.([^.]+))?$/;
            let extArray = re.exec(context.file.name);
            let extension = null
            if (extArray) {
              extension = extArray[1]
            }
            return context.entityId + (extension ? '.' + extension : '');
          },
          storagePath: (_) => {
            // example path: '/c/Project/nahuelbuta/Media/9ft6874k78g9HvzWDG40'
            //               0/1/   2   /    3     /       
            const document_id = window.location.pathname.split('/')[3]
            const collection = window.location.pathname.split('/')[2]
            return collection + "/" + document_id + "/Media"
          },
          acceptedFiles: ["image/jpeg", "image/png"],
          storeUrl: true
        }
      },
      disabled: !entityId && { disabledMessage: 'Please create media before load file' }
    }),
    labels: {
      title: "Labels",
      description: "Tags for image.",
      validation: { required: true },
      dataType: "array",
      of: {
        dataType: "string",
        config: {
          enumValues: {
            "species": "Species",
            "steward-photo": "Steward photo",
            "aerial-image": "Aerial image",
            "featured-image": "Featured image",
            "cropped-image": "Cropped image"
          }
        }
      }
    },
    metadata: {
      title: "Metadata",
      description: "Image metadata and atribution",
      dataType: "map",
      properties: {
        src: {
          title: "src",
          description: "Name of the source.",
          dataType: "string",
          validation: { required: true }
        },
        owner: {
          title: "owner",
          description: "Name of the image's owner.",
          dataType: "string",
          validation: { required: true }
        },
        uri: {
          title: "uri",
          description: "Source's link.",
          dataType: "string",
          validation: { required: true }
        },
        resolution: {
          title: "resolution",
          description: "Image's resolution.",
          dataType: "string",
          validation: { required: false }
        }
      }
    },
    timestamp_last_update: {
      title: "Timestamp last update",
      description: "Last time the data was updated",
      dataType: "timestamp",
      autoValue: "on_update",
      disabled: true
    }
  }
});
