import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { Announcement } from "../types";
import { sendAnnouncementToUsers } from "../utils";
const firebaseConfig = require('./../firebase-config')
export const announcementCallbacks: EntityCallbacks = {
    onPreSave: ({
        schema,
        path,
        entityId,
        values,
        status,
        context
    }) => {
        let app_env = "app";
        switch (firebaseConfig.projectId) {
            case "lemu-dev":
                app_env += "-dev";
                break;
            case "lemu-stag":
                app_env += "-stag";
                break;
            case "lemu-expo":
                app_env += "-expo";
                break;
            default:
                break;
        }
        if (!values.cta.link_suffix.startsWith("/")) {
            values.cta.link_suffix = "/" + values.cta.link_suffix
        }
        values.cta.link = `https://${app_env}.le.mu/?link=https://le.mu${values.cta.link_suffix}`
        return values
    },
    onSaveSuccess: async (props) => {
        console.log("onSaveSuccess props", props);
        if (!props.values.user_emails) props.values.user_emails = "";
        const prev = props.previousValues!
        const actual = props.values;
        const announcementId = props.entityId;
        if (actual.status === "ACTIVE") {
            console.log("calling sendAnnouncementToUsers")
            console.log(announcementId, prev, actual)
            sendAnnouncementToUsers(announcementId, prev, actual)
        }
    },
    onPreDelete: (props) => {
        console.log("onPreDelete props", props);
    }
};

export const announcementSchema = buildSchema<Announcement>({
    name: "Announcement",
    customId: true,
    properties: {
        title: {
            title: "Title",
            description: "Title of the announcement",
            validation: { required: true, max: 35 },
            dataType: "string"
        },
        body: {
            title: "Body",
            description: "Body of the announcement",
            validation: { required: true, max: 100 },
            config: {
                multiline: true
            },
            dataType: "string"
        },
        cta: {
            title: "CTA",
            dataType: "map",
            description: "Call to action",
            properties: {
                label: {
                    title: "Label of the CTA",
                    dataType: "string"
                },
                link: {
                    title: "Link",
                    dataType: "string",
                    readOnly: true
                },
                link_suffix: {
                    title: "Link Suffix",
                    dataType: "string",
                    description: "For more info on how to construct links: https://www.notion.so/lemuearth/Dynamic-Links-697ac21bddce421394b083b207f52752 (section CTA-Links)"
                }
            }
        },
        user_emails: {
            title: "User's email",
            dataType: "string",
            config: {
                multiline: true
            },
            description: "User's email separated by ','. Leave empty for all Users.",
        },
        start_at: {
            title: "Start at",
            description: "Initial date for the announcement",
            dataType: "timestamp"
        },
        end_at: {
            title: "End at",
            description: "Ending date for the announcement",
            dataType: "timestamp"
        },
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        },
        status: {
            title: "Status",
            description: "Announcement status",
            validation: { required: true },
            config: {
                enumValues: {
                    DRAFT: { label: "Draft", color: "grayLight" },
                    READY: { label: "Ready", color: "cyanLight" },
                    PENDING: { label: "Pending", color: "yellowLight", disabled: true },
                    ACTIVE: { label: "Active", color: "greenLight" },
                    DISABLED: { label: "Disabled", color: "redLight" }
                }
            },
            dataType: "string"
        },
    }
});
