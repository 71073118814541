import { buildSchema } from "@camberi/firecms";
import { Article, Category } from "../types";

export const categorySchema = buildSchema<Category>({
    name: "Category",
    customId: false,
    properties: {
      title: {
        title: "Title",
        description: "Title of category",
        validation: { required: true },
        dataType: "map",
        properties: {
            en: {
                title: "EN",
                dataType: "string"
            },
            es: {
                title: "ES",
                dataType: "string"
            }
        }
      },
      icon_uri: {
          title: "Icon URI",
          description: "Organization icon",
          validation: { required: false },
          dataType: "string",
          config: {
              storageMeta: {
                  mediaType: "image",
                  storagePath: "project",
                  acceptedFiles: ["image/*"],
                  storeUrl: true
              }
          }
      }
    }
});


export const articleSchema = buildSchema<Article>({
  name: "Article",
  customId: false,
  properties: {
    title: {
      title: "Title",
      description: "Title of category",
      validation: { required: true },
      dataType: "map",
      properties: {
          en: {
              title: "EN",
              dataType: "string"
          },
          es: {
              title: "ES",
              dataType: "string"
          }
      }
    },
    icon_uri: {
        title: "Icon URI",
        description: "Organization icon",
        validation: { required: false },
        dataType: "string",
        config: {
            storageMeta: {
                mediaType: "image",
                storagePath: "post",
                acceptedFiles: ["image/*"],
                storeUrl: true
            }
        }
    },
    content_uri: {
      title: "Content URI",
      description: "URI of the content",
      validation: { required: true },
      dataType: "map",
      properties: {
          en: {
              title: "EN",
              dataType: "string",
              validation: { url: true }
          },
          es: {
              title: "ES",
              dataType: "string",
              validation: { url: true }
          }
      }
    },
    featured: {
        title: "Featured",
        description: "Represent if this article could appear in explore or not.",
        dataType:"boolean"
    }
  }
});
