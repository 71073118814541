import React, { ChangeEvent, useRef, useState } from "react";
import { Button } from "@mui/material";
import { Entity, SelectionController, useSnackbarController } from "@camberi/firecms";
import { getFirestore, writeBatch, doc, collection  } from "firebase/firestore";
import { InvitationRequest } from "../types";
import { invitationCodeGen } from "../utils";

const supportedFields = [
  "first_name",
  "last_name",
  "email",
  "organization_name",
  "group_name",
  "language"
]
const BATCH_MAX_SIZE = 500

export const InvitationRequestActionImport = ({ selectionController }: {
    selectionController: SelectionController
}) => {
    const [busy, setBusy] = useState(false);
    const inputFile = useRef(null) 
    const snackbarContext = useSnackbarController();

    const readFile = (file: File): Promise<InvitationRequest[]> => {
      return new Promise((resolve, reject) => {
        const csvReader = new FileReader();

        csvReader.onload = (e: ProgressEvent<FileReader>) => {
          const result: InvitationRequest[] = []
          if (e && e.target) {
            const text = e.target.result;
            if(typeof text === 'string' || text instanceof String) {
                const rows = text.split(/[\n]+/);
                const fieldNames: string[] = rows[0].split(',').map(v => v.trim())

                const allCorrects = fieldNames.some(f => supportedFields.includes(f)) //check fields
                if (!allCorrects) {
                  reject(Error(`Field(s) not supported!, use ${supportedFields.join(', ')}`))
                }
                
                for (let i = 1; i< rows.length; i++) {
                  const row = rows[i].split(',')
                  if (row.length !== fieldNames.length){
                    continue
                  }

                  const d: any = {}
                  row.forEach((value: any, index) => {
                    const f = fieldNames[index]
                    d[f] = value.trim()
                  })
                  result.push(d as InvitationRequest)
                }  
            }
          }
          resolve(result)
        }
        csvReader.onerror = reject;
        csvReader.readAsText(file)
      })
    }

    const uploadData = async (invitations: InvitationRequest[]) => {
      const db = getFirestore()
      const collRef = collection(db, "InvitationRequest")
      const entities: Entity<InvitationRequest>[] = []

      let batch = writeBatch(db)
      let counter = 0
      for (let i in invitations) {
        const docRef = doc(collRef)
        const invitation: InvitationRequest = invitations[i]
        invitation["invite_code"] = invitationCodeGen()
        invitation["state"] = "APPROVED"
        invitation["registered_at"] = new Date()
        invitation["origin"] = "FILE"
        batch.set(docRef, invitation)

        entities.push({'id': docRef.id, "path": docRef.path, "values": invitation})

        counter++
        if (counter === BATCH_MAX_SIZE) {
          await batch.commit()
          counter = 0
          batch = writeBatch(db)
        }
      }
      if (counter > 0) {
        await batch.commit()
      }
      return entities
    }

    const processFile = async (file: File) => {
      try {
        setBusy(true)
        const invitations = await readFile(file)
        if (invitations.length === 0) {
          snackbarContext.open({type: "warning", message: `No data to import`});
          return
        }
        
        const entities = await uploadData(invitations)

        selectionController.setSelectedEntities(entities)

        snackbarContext.open({type: "success",message: `${invitations.length} rows successfully imported`});
      } catch(e) {
        snackbarContext.open({ type: "error", message: `Error importing csv file: ${(e as Error).message}`});
      } finally {
        setBusy(false)
      }
    }

    const onClickImport = (e: React.MouseEvent) => {
        if (inputFile && inputFile.current) {
          const input: HTMLInputElement = inputFile.current
          input.click();
          input.value = ''
        }
    };

    const onChangeFile = async (e: ChangeEvent)  => {
        if (e && e.target) {
          const files = (e.target as HTMLInputElement).files
          if (files && files.length > 0) {
            await processFile(files[0])
        }
      }
    }

    return (
        <>
          <Button onClick={onClickImport} color="primary" disabled={busy} title="Import invitations from csv">
            import
            <input id="importFile"
              type="file"
              ref={inputFile}
              accept='.csv'
              style={{display: 'none'}}
              onChange={onChangeFile}
          />
        </Button>
        </>  
    );

}

