import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { Badge } from "../types";
import { generateThumbnails } from "../utils";


export const badgeCallbacks: EntityCallbacks = {
    onPreSave: ({
        schema,
        path,
        entityId,
        values,
        status,
        context
    }) => {
        console.log("OnPresave");

        return values
    },
    onSaveSuccess: async (props) => {
        console.log("onSaveSuccess (Badges)", props);
        const dataSource = props.context.dataSource
        const prev = props.previousValues!
        let actual = props.values

        await generateThumbnails(prev, actual, 'uri_on', 'src_on', 'Badge/' + props.entityId)
        await generateThumbnails(prev, actual, 'uri_off', 'src_off', 'Badge/' + props.entityId)


        // update values with icon_src
        const entity = await dataSource.fetchEntity({
            path: props.path,
            entityId: props.entityId ? props.entityId : "",
            schema: props.schema
        })
        actual = entity.values
        console.log("new data", actual)
    },
    onPreDelete: (props) => {
        console.log("onPreDelete props", props);
    }
};

export const badgeSchema = buildSchema<Badge>({
    name: "Badge",
    customId: true,
    properties: {
        name: {
            title: "Name",
            description: "Badge's name",
            dataType: "string",
            validation: { required: true }
        },
        tagline: {
            title: "Tagline",
            description: "Tagline",
            dataType: "string",
            validation: { required: false }
        },
        description: {
            title: "Description",
            description: "Badge's description",
            dataType: "string",
            validation: { required: true }
        },
        uri_on: ({ entityId }) => ({
            title: "Source On URI",
            description: "Badge's image ON",
            validation: { required: false },
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: (context) => {
                        return "Badge/" + context.entityId;
                    },
                    fileName: (context) => {
                        const re = /(?:\.([^.]+))?$/;
                        let extArray = re.exec(context.file.name);
                        let extension = null
                        if (extArray) {
                            extension = extArray[1]
                        }
                        return 'badge_on' + (extension ? '.' + extension : '');
                    },
                    acceptedFiles: ["image/jpeg", "image/png"],
                    storeUrl: true
                }
            },
            disabled: !entityId && { disabledMessage: 'Please create badge before load files' }
        }),
        uri_off: ({ entityId }) => ({
            title: "Source OFF URI",
            description: "Badge's image OFF",
            validation: { required: false },
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: (context) => {
                        return "Badge/" + context.entityId;
                    },
                    fileName: (context) => {
                        const re = /(?:\.([^.]+))?$/;
                        let extArray = re.exec(context.file.name);
                        let extension = null
                        if (extArray) {
                            extension = extArray[1]
                        }
                        return 'badge_off' + (extension ? '.' + extension : '');
                    },
                    acceptedFiles: ["image/jpeg", "image/png"],
                    storeUrl: true
                }
            },
            disabled: !entityId && { disabledMessage: 'Please create badge before load files' }
        }),
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        },
    }
});
