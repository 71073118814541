import { buildSchema } from "@camberi/firecms";
import { Program } from "../types";


export const programSchema = buildSchema<Program>({
    name: "Programs",
    customId: true,
    properties: {
        name: {
            title: "Name",
            description: "Name of the program",
            validation: { required: true },
            dataType: "string"
        },
        short_name: {
            title: "Short Name",
            description: "Short Name of the program",
            validation: { required: true },
            dataType: "string"
        },
        description: {
            title: "Description",
            description: "Description of the program",
            validation: { required: true },
            dataType: "string"
        },
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        }
    }
});
