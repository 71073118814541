import React from "react";

import { User as FirebaseUser } from "firebase/auth";
import {
    Authenticator,
    buildCollection,
    // buildProperty,
    // buildSchema,
    // EntityReference,
    FirebaseCMSApp,
    FirestoreTextSearchController,
    performAlgoliaTextSearch,
    NavigationBuilder,
    NavigationBuilderProps,
    AdditionalColumnDelegate
} from "@camberi/firecms";
import { getApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import algoliasearch, { SearchClient } from "algoliasearch";

import { organizationCallbacks, organizationSchema } from "./schemas/organization_schema";
import { projectCallbacks, projectSchema } from "./schemas/project_schema";
import { userSchema } from "./schemas/user_schema";
import { mediaSchema, mediaCallbacks } from "./schemas/media_schema";
import { articleSchema, categorySchema } from "./schemas/post_schema";
import { invitationRequestCallbacks, invitationRequestSchema, invitationRequestExtraActions } from "./schemas/invitation_request_schema";
import { dataLayerLegendSchema, dataLayerSchema, dataLayerDetailsSchema, dataLayerDetailsCallbacks } from "./schemas/datalayer_schema";
import { speciesCallbacks, speciesSchema } from "./schemas/species_schema";
import { featuredPlaceCallbacks, featuredPlaceSchema } from "./schemas/featured_places_schema";
import { announcementCallbacks, announcementSchema } from "./schemas/announcement_schema";
import { programSchema } from "./schemas/program_schema";
import { couponCallbacks, couponSchema } from "./schemas/coupon_schema";
import { badgeCallbacks, badgeSchema } from "./schemas/badge_schema";


import "typeface-rubik";
import "typeface-space-mono";

import Logo from './logo-green.svg';

import { Project } from "./types";
import { Button, Link } from "@mui/material";

const firebaseConfig = require('./firebase-config')

// Search setup with algolia
const algoliaConfig = require('./algolia-config')
const client: SearchClient | undefined = algoliasearch(algoliaConfig["app_id"], algoliaConfig["search_key"])
const projectsIndex = client.initIndex("project")
const usersIndex = client.initIndex("user")
const organizationsIndex = client.initIndex("organization")
const speciesIndex = client.initIndex("species")
const featuredPlaceIndex = client.initIndex("featuredPlace")

// Text search
const textSearchController: FirestoreTextSearchController =
    //TODO: Agregar Species a algolia
    ({ path, searchString }) => {
        console.log('textSearch', path, searchString)
        if (path === "Project")
            return performAlgoliaTextSearch(projectsIndex, searchString);
        if (path === "User")
            return performAlgoliaTextSearch(usersIndex, searchString);
        if (path === "Organization")
            return performAlgoliaTextSearch(organizationsIndex, searchString);
        if (path === "Species")
            return performAlgoliaTextSearch(speciesIndex, searchString);
        if (path === "FeaturedPlace")
            return performAlgoliaTextSearch(featuredPlaceIndex, searchString);
        return undefined;
    };

//button for project
export const buttonToMap: AdditionalColumnDelegate<Project> = {
    id: "to_map",
    title: "Go to map",
    builder: ({ entity }) => {
        let values = entity.values;
        let url = "https://elun-lsdmt5rcjq-uc.a.run.app/en?lat=" + values.location.lat + "&lng=" + values.location.lng + "&zoom=14"
        return <>
            <Link href={url} target="_blank">
                <Button variant="contained">Go</Button>
            </Link>
        </>;
    },
    dependencies: ["name"]
};

export default function App() {

    const navigation: NavigationBuilder = async ({
        user,
        authController,

    }: NavigationBuilderProps) => {

        return ({
            collections: [
                buildCollection({
                    path: "Organization",
                    schema: organizationSchema,
                    name: "Organizations",
                    callbacks: organizationCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    subcollections: [
                        buildCollection({
                            name: "Media",
                            path: "Media",
                            schema: mediaSchema,
                            callbacks: mediaCallbacks
                        })
                    ],
                    textSearchEnabled: true
                }),
                buildCollection({
                    path: "Project",
                    schema: projectSchema,
                    callbacks: projectCallbacks,
                    name: "Projects",
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    subcollections: [
                        buildCollection({
                            name: "Media",
                            path: "Media",
                            schema: mediaSchema,
                            callbacks: mediaCallbacks
                        })
                    ],
                    textSearchEnabled: true,
                    additionalColumns: [
                        buttonToMap
                    ]
                }),
                buildCollection({
                    path: "User",
                    schema: userSchema,
                    // callbacks: userCallbacks,
                    name: "Users",
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    textSearchEnabled: true
                }),
                buildCollection({
                    path: "Post",
                    schema: categorySchema,
                    // callbacks: userCallbacks,
                    name: "Posts",
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    subcollections: [
                        buildCollection({
                            name: "Articles",
                            path: "Article",
                            schema: articleSchema
                        })
                    ]
                }),
                buildCollection({
                    path: "InvitationRequest",
                    schema: invitationRequestSchema,
                    name: "Invitation Requests",
                    callbacks: invitationRequestCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    textSearchEnabled: false,
                    extraActions: invitationRequestExtraActions,
                }),
                buildCollection({
                    path: "DataLayer",
                    schema: dataLayerSchema,
                    // callbacks: userCallbacks,
                    name: "Data Layers",
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    subcollections: [
                        buildCollection({
                            name: "Legend",
                            path: "Legend",
                            schema: dataLayerLegendSchema
                        }),
                        buildCollection({
                            name: "Details",
                            path: "Details",
                            schema: dataLayerDetailsSchema,
                            callbacks: dataLayerDetailsCallbacks
                        })
                    ]
                }),
                buildCollection({
                    path: "Species",
                    schema: speciesSchema,
                    name: "Species",
                    callbacks: speciesCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    subcollections: [
                        buildCollection({
                            name: "Media",
                            path: "Media",
                            schema: mediaSchema,
                            callbacks: mediaCallbacks
                        })
                    ],
                    textSearchEnabled: true
                }),
                buildCollection({
                    path: "FeaturedPlace",
                    schema: featuredPlaceSchema,
                    name: "Featured Places",
                    callbacks: featuredPlaceCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    }),
                    subcollections: [
                        buildCollection({
                            name: "Media",
                            path: "Media",
                            schema: mediaSchema,
                            callbacks: mediaCallbacks
                        })
                    ],
                    textSearchEnabled: true
                }),
                buildCollection({
                    path: "Announcement",
                    schema: announcementSchema,
                    name: "Announcements",
                    callbacks: announcementCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    })
                }),
                buildCollection({
                    path: "Program",
                    schema: programSchema,
                    name: "Programs",
                    permissions: ({ authController }) => ({
                        // edit: false,
                        // create: false,
                        // delete: false
                        edit: true,
                        create: true,
                        // we have created the roles object in the navigation builder
                        delete: authController.extra.roles.includes("admin")
                    })
                }),
                buildCollection({
                    path: "Coupon",
                    schema: couponSchema,
                    name: "Coupons",
                    callbacks: couponCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        delete: false
                    })
                }),
                buildCollection({
                    path: "Badge",
                    schema: badgeSchema,
                    name: "Badges",
                    callbacks: badgeCallbacks,
                    permissions: ({ authController }) => ({
                        edit: true,
                        create: true,
                        delete: true
                    }),
                    textSearchEnabled: true
                }),
            ]
        });
    };

    const myAuthenticator: Authenticator<FirebaseUser> = async ({
        user,
        authController
    }) => {
        const email = user?.email

        if (email?.endsWith('odd.co') || email?.endsWith('le.mu')) {
            console.log("Allowing access to", user?.email);

            const sampleUserData = await Promise.resolve({
                roles: ["admin"]
            });
            authController.setExtra(sampleUserData);
            return true
        } else {
            console.log("Access denied for ", user?.email);
            return false
        }

    };

    const onFirebaseInitHandler = (config: any) => {
        if ('appCheckDebug' in config && config['appCheckDebug']) {
            (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
        }

        console.log('initializing App Check')
        initializeAppCheck(getApp(), {
            provider: new ReCaptchaEnterpriseProvider(config['recaptchaKey']),
            isTokenAutoRefreshEnabled: true,
        });
    }

    return <FirebaseCMSApp
        name={"Backend (" + firebaseConfig.projectId + ")"}
        logo={Logo}
        authentication={myAuthenticator}
        navigation={navigation}
        firebaseConfig={firebaseConfig}
        textSearchController={textSearchController}
        onFirebaseInit={onFirebaseInitHandler}
    />;
}
