import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { Coupon } from "../types";
import { sendCouponToUsers } from "../utils";

export const couponCallbacks: EntityCallbacks = {
    onPreSave: ({
        schema,
        path,
        entityId,
        values,
        status,
        context
    }) => {
        console.log("OnPresave");

        return values
    },
    onSaveSuccess: async (props) => {
        console.log("onSaveSuccess props", props);
        if (!props.values.user_emails) props.values.user_emails = "";
        const prev = props.previousValues!
        const actual = props.values;
        const couponId = props.entityId;
        if (actual.status === "CREATED") {
            console.log("calling sendCouponToUsers")
            console.log(couponId, prev, actual)
            sendCouponToUsers(couponId, prev, actual)
        }
    },
    onPreDelete: (props) => {
        console.log("onPreDelete props", props);
    }
};

export const couponSchema = buildSchema<Coupon>({
    name: "Coupon",
    customId: true,
    properties: {
        amount: {
            title: "Amount",
            description: "Monto del cupon",
            dataType: "number",
            validation: {
                min: 25,
                max: 25
            }
        },
        user_emails: {
            title: "User's email",
            dataType: "string",
            config: {
                multiline: true
            },
            description: "User's email separated by ','. Leave empty for all Users.",
        },
        seconds_active: {
            title: "Seconds active",
            description: "Seconds that the coupon will be alive after activation.",
            dataType: "number"
        },
        status: {
            title: "Status",
            description: "Initial status of the coupon for the users.",
            validation: { required: true },
            config: {
                enumValues: {
                    CREATED: { label: "Created", color: "grayLight" },
                    ACTIVATED: { label: "Activated", color: "greenLight" },
                    CANCELED: { label: "Canceled", color: "redLight" },
                    USED: { label: "Used", color: "redLight" }
                }
            },
            dataType: "string"
        },
        utm_code: {
            title: "UTM code",
            dataType: "string"
        },
        origin: {
            title: 'Origin',
            dataType: "string"
        },
        announcement: {
            title: 'Announcement',
            dataType: "reference",
            path: "Announcement",
            previewProperties: ["title", "body"]
        },
        email_template: {
            title: 'Email template id',
            description: 'Sendgrid template id used for email. Example: d-ddf233af99a2410cbf971777e27c4f24',
            dataType: 'string',
            validation: { required: true }
        },
        timestamp_expired_at: {
            title: "Timestamp expired at",
            description: "Date of expiration depending on the status.",
            dataType: "timestamp",
        },
        timestamp_created_at: {
            title: "Timestamp created at",
            description: "Date of creation.",
            dataType: "timestamp",
            autoValue: "on_create",
            disabled: true
        }
    }
});
