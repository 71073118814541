import { buildSchema, EntityCallbacks, ExtraActionsParams } from "@camberi/firecms";
import { InvitationRequest } from "../types";
import { InvitationRequestActionImport } from "../actions/invitation_request_action_import";
import { InvitationRequestActionSync } from "../actions/invitation_request_action_sync";
import { invitationCodeGen } from "../utils";


export const invitationRequestCallbacks: EntityCallbacks = {
    onPreSave: ({
        schema,
        path,
        entityId,
        values,
        status,
        context,
        previousValues
    }) => {
        if (previousValues && previousValues.state === 'PENDING' && values.state === 'APPROVED') {
            console.log("State changed from PENDING to APPROVED, generating code")

            values.invite_code = invitationCodeGen()
        }

        if (previousValues === undefined) { // creando invitation
          values.origin = 'MANUAL'
        }
        return values;
    },

    onSaveSuccess: (props) => {
    },

};

export const invitationRequestSchema = buildSchema<InvitationRequest>({
    name: "InvitationRequest",
    customId: false,
    properties: {
        first_name: {
            title: "First Name",
            validation: { required: false },
            dataType: "string"
        },
        last_name: {
            title: "Last name",
            validation: { required: false },
            dataType: "string"
        },
        email: {
            title: "Email",
            validation: { required: false },
            dataType: "string"
        },
        user_type: {
            title: "User Type",
            validation: { required: false },
            dataType: "string",
            description: "Can be Steward, Investor or Other",
            config: {
              enumValues: {
                  "UserType.investor": { label: "Investor"},
                  "UserType.steward": { label: "Steward"},
                  "UserType.enthusiast": { label: "Enthusiast"},
                  "UserType.other": { label: "Other"}
              }
          }
        },
        group_name: {
            title: "Group Name",
            validation: { required: false },
            dataType: "string"
        },
        language: {
            title: "Language",
            validation: { required: false },
            dataType: "string"
        },
        region_info: {
            title: "Region Info",
            validation: { required: false },
            dataType: "string",
            description: "Country"
        },
        organization_name: {
            title: "Organization Name",
            validation: { required: false },
            dataType: "string"
        },
        invite_code: {
            title: "Invite Code",
            validation: { required: false },
            dataType: "string",
            disabled: true,
            description: "6 digits, autogenerated only when a Invitation changes from PENDING to APPROVED"
        },
        registered_at: {
            title: "Registered At",
            validation: { required: false },
            dataType: "timestamp",
            autoValue: "on_create",
            disabled: { hidden: false }
        },
        reviewed_at: {
            title: "Reviewed At",
            validation: { required: false },
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: { hidden: true }
        },
        completed_at: {
            title: "Completed At",
            validation: { required: false },
            dataType: "timestamp",
            disabled: { hidden: true }
        },
        state: {
            title: "State",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: {
                    PENDING: { label: "Pending", color: "yellowLight" },
                    APPROVED: { label: "Approved", color: "greenLight" },
                    REJECTED: { label: "Rejected", color: "redDark" },
                    DONE: { label: "Done", color: "greenDark", disabled: true },
                }
            },
            description: "PENDING: the Invitation is registered and pending to review. APPROVED, REJECTED: self-explanatory. DONE: the User uses the Invitation and complete the registration process (cannot be changed by LEMU)."
        },
        mailchimp_sync: {
          title: "Sync",
          disabled: true,
          dataType: "string",
          config: {
              enumValues: {
                  DONE: { label: "Done", color: "greenLight" },
                  ERROR: { label: "Error", color: "redDark" }
              }
          }
        },
        origin: {
          title: "Origin",
          disabled: true,
          dataType: "string",
          config: {
              enumValues: {
                  APP: { label: "App"},
                  FILE: { label: "File"},
                  MANUAL: { label: "Manual"}
              }
          }
        }
    }
});

export const invitationRequestExtraActions = ({
  selectionController
}: ExtraActionsParams) => {
return (
    <>
      <InvitationRequestActionSync
          selectionController={selectionController}/>
          
      <InvitationRequestActionImport
          selectionController={selectionController}/>
    </>
  );
};
