import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { DataLayer, DataLayerDetails, DataLayerLegend } from "../types";
import ColorPreview from "../previews/color_preview"


export const dataLayerDetailsCallbacks: EntityCallbacks = {
  onPreSave: ({
      schema,
      path,
      entityId,
      values,
      status,
      context
  }) => {
      console.log("onPreSave", values);
      const legend = values.legend
      for (let i in legend) {
        if(Object.values(legend[i].description).filter(d => d != null).length === 0){
          delete values.legend[i].description
        }
      }
      return values
  },
  onSaveSuccess: (props) => {
  }
};


export const dataLayerSchema = buildSchema<DataLayer>({
    name: "DataLayer",
    customId: false,
    properties: {
      name: {
        title: "Name",
        description: "Name of the data layer",
        dataType: "map",
        properties: {
            en: {
                title: "EN",
                dataType: "string",
                validation: { required: true },
            },
            es: {
                title: "ES",
                dataType: "string"
            }
          }
        },
        uri: {
          title: "URI",
          description: "URI to data layer map tiles",
          validation: { required: true },
          dataType: "string",
          config: {
            url: true
          }
        },
        status: {
            title: "Status",
            validation: { required: true },
            config: {
                enumValues: {
                    DRAFT: { label: "Draft", color: "grayLight" },
                    ACTIVE: { label: "Active", color: "greenLight"},
                    DISABLED: { label: "Disabled", color: "redLight"}
                }
            },
            dataType: "string"
        }
    }
});

export const dataLayerDetailsSchema = buildSchema<DataLayerDetails>({
      name: "Details",
      customId: {
        "details": "details",
      },
      properties: {
        name: {
          title: "Name",
          description: "Name of the data layer",
          dataType: "map",
          properties: {
              en: {
                  title: "EN",
                  dataType: "string",
                  validation: { required: true },
              },
              es: {
                  title: "ES",
                  dataType: "string"
              }
          }
        },
        description: {
          title: "Description",
          description: "Description of the data layer",
          dataType: "map",
          properties: {
              en: {
                  title: "EN",
                  dataType: "string",
                  validation: { required: true },
                  config: {
                    multiline: true
                  }
              },
              es: {
                  title: "ES",
                  dataType: "string",
                  config: {
                    multiline: true
                  }
              }
          }
        },
        unit: {
          title: "Unit",
          description: "Data unit",
          validation: { required: true },
          dataType: "string"
        },
        data_source: {
          title: "Data source",
          description: "Data source name",
          validation: { required: true },
          dataType: "string"
        },
        data_source_uri: {
          title: "Data source uri",
          description: "Data source URI",
          validation: { required: true },
          dataType: "string",
          config: {
            url: true
          }
        },
        legend: {
          title: "Legend",
          validation: { required: true },
          dataType: "array",
          of: {
            dataType: "map",
            properties: {
              color: {
                title: "color",
                dataType: "string",
                validation: { required: true },
                config: {
                    Preview: ColorPreview
                }
              },
              value: {
                title: "value",
                dataType: "string",
                validation: { required: true },
              },
              description: {
                title: "description",
                dataType: "map",
                properties: {
                  en: {
                      title: "EN",
                      dataType: "string",
                      config: {
                        multiline: true
                      }
                  },
                  es: {
                      title: "ES",
                      dataType: "string",
                      config: {
                        multiline: true
                      }
                  }
                }
              }
            }
        } 
      }
    }
});

export const dataLayerLegendSchema = buildSchema<DataLayerLegend>({
      name: "Legend",
      customId: {
        "legend": "legend",
      },
      properties: {
        title: {
          title: "Title",
          description: "Title of legend",
          validation: { required: true },
          dataType: "string"
        },
        subtitle: {
          title: "Subtitle",
          description: "Subtitle of legend",
          validation: { required: true },
          dataType: "string"
        },
        legend: {
          title: "Legend",
          validation: { required: true },
          dataType: "array",
          of: {
            dataType: "map",
            properties: {
              color: {
                title: "color",
                dataType: "string",
                validation: { required: true },
                config: {
                    Preview: ColorPreview
                }
              },
              value: {
                title: "value",
                dataType: "string",
                validation: { required: true },
              }
            }
        } 
      }
    }
});
