import React from "react";
import { PreviewComponentProps } from "@camberi/firecms";


export default function ColorPreview({
                        name,
                        value,
                        property,
                        size
                      }: PreviewComponentProps<string>): React.ReactElement {

    const reg=/^#([0-9a-f]{3}){1,2}$/i;
    let style = null

    if (value && reg.test(value)) {
      style = { style: { backgroundColor: value } }
    }                    
    return React.createElement("div", style, value)
}