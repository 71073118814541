import { buildSchema } from "@camberi/firecms";
import { User } from "../types";

export const userSchema = buildSchema<User>({
    name: "Organization",
    customId: true,
    properties: {
        id: {
            title: "id",
            dataType: "string",
            validation: { required: true }
        },
        first_name: {
            title: "First Name",
            dataType: "string",
            validation: { required: true }
        },
        last_name: {
            title: "Last Name",
            dataType: "string",
            validation: { required: true }
        },
        username: {
            title: "Username",
            dataType: "string",
            validation: {
                required: true,
                matches: /^[a-zA-Z0-9_.-]+$/
            }
        },
        phone_number: {
            title: "Phone Number",
            dataType: "string",
            validation: { required: true }
        },
        email: {
            title: "Email",
            dataType: "string",
            validation: { required: true }
        },
        type: {
            title: "Type",
            dataType: "string",
            validation: { required: false },
            config: {
                enumValues: {
                    Lemu: { label: "Lemu" },
                    Steward: { label: "Steward" },
                    User: { label: "User" },
                }
            }
        },
        is_email_validated: {
            title: "Is Email Validated",
            dataType: "boolean",
            validation: { required: true }
        },
        avatar_uri: {
            title: "Avatar URI",
            dataType: "string",
            validation: { required: true }
        },
        biography: {
            title: "Biography",
            dataType: "string",
            validation: { required: true }
        },
        followers: {
            title: "Followers",
            dataType: "number",
            validation: { required: false }
        },
        following: {
            title: "Following",
            dataType: "number",
            validation: { required: false }
        },
        registered_at: {
            title: "Registered At",
            dataType: "timestamp",
            validation: { required: true }
        }
    }
});
