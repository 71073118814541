import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { Organization } from "../types";
import { projectSchema } from "./project_schema";
import { generateThumbnails } from "../utils";

export const organizationCallbacks: EntityCallbacks = {
    onPreSave: (props) => {
        console.log("onPreSave");
        if (!props.values.about.es) props.values.about.es = "";
        // if (!props.values.overview.es) props.values.overview.es = "";
        if (!props.values.certified) props.values.certified = false;
        return props.values;
    },

    onSaveSuccess: async (props) => {
        console.log("onSaveSuccess (Organization)", props);
        const dataSource = props.context.dataSource
        const prev = props.previousValues!
        let actual = props.values

        await generateThumbnails(prev, actual, 'icon_uri', 'icon_src', 'Organization/' + props.entityId)

        // update values with icon_src
        const entity = await dataSource.fetchEntity({
            path: props.path,
            entityId: props.entityId ? props.entityId : "",
            schema: props.schema
        })
        actual = entity.values
        console.log("new data", actual)


        const projects = await dataSource.fetchCollection({
            "path": "Project",
            "schema": projectSchema,
            "filter": { "organization_id": ["==", props.entityId] }
        })

        for (let i in projects) {
            const project = projects[i]
            let values = project.values
            values['organization'] = actual as Organization

            await dataSource.saveEntity({
                "entityId": project.id,
                "path": "Project",
                "schema": projectSchema,
                "values": values,
                "status": "existing"
            })
        }
    },

};

export const organizationSchema = buildSchema<Organization>({
    name: "Organization",
    customId: true,
    properties: {
        name: {
            title: "Name",
            description: "Organization's full name.",
            validation: { required: true },
            dataType: "string"
        },
        short_name: {
            title: "Short Name",
            description: "Organization's short name.",
            validation: { required: true },
            dataType: "string"
        },
        about: {
            title: "About",
            description: "Organization's long description.",
            dataType: "map",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { max: 2000 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 2000 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        contact: {
            title: "Contact Information",
            description: "Contact information and Social Media links",
            validation: { required: false },
            dataType: "map",
            properties: {
                website: {
                    title: "Website",
                    dataType: "string",
                    validation: { url: true }
                },
                phone: {
                    title: "Phone number",
                    description: "Must be in the following format: +1 1 11111111",
                    dataType: "string",
                    validation: {
                        // eslint-disable-next-line
                        matches: /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
                    }
                },
                email: {
                    title: "Email",
                    dataType: "string",
                    validation: { email: true }
                },
                social_media: {
                    title: "Social media links (2 maximum)",
                    dataType: "array",
                    oneOf: {
                        typeField: "code",
                        valueField: "url",
                        properties: {
                            facebook: {
                                dataType: "string",
                                title: "Facebook",
                                config: { url: true },
                                validation: { url: true }
                            },
                            instagram: {
                                dataType: "string",
                                title: "Instagram",
                                config: { url: true },
                                validation: { url: true }
                            },
                            linkedin: {
                                dataType: "string",
                                title: "LinkedIn",
                                config: { url: true },
                                validation: { url: true }
                            },
                            twitter: {
                                dataType: "string",
                                title: "Twitter",
                                config: { url: true },
                                validation: { url: true }
                            },
                            youtube: {
                                dataType: "string",
                                title: "YouTube",
                                config: { url: true },
                                validation: { url: true }
                            },
                        }
                    }
                }
            }
        },
        // TODO Verificar uso del campo
        location_string: {
            title: "Location",
            description: "Organization's location.",
            validation: { required: true },
            dataType: "string"
        },
        // TODO Verificar uso del campo
        location: {
            title: "Location",
            description: "Organization lat, lng coordinates",
            dataType: "map",
            properties: {
                lat: {
                    title: "lat",
                    dataType: "number",
                    validation: { required: true }
                },
                lng: {
                    title: "lng",
                    dataType: "number",
                    validation: { required: true }
                }
            }
        },
        established: {
            title: "Established (year)",
            description: "Organization's starting date.",
            dataType: "number"
        },
        // TODO: add defaultValue (available in version 2.0)
        certified: {
            title: "Certified",
            dataType: 'boolean',
            disabled: { hidden: true }
        },
        icon_uri: ({ entityId }) => ({
            title: "Icon URI",
            description: "Organization icon",
            validation: { required: false },
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: (context) => {
                        return "Organization/" + context.entityId;
                    },
                    fileName: (context) => {
                        const re = /(?:\.([^.]+))?$/;
                        let extArray = re.exec(context.file.name);
                        let extension = null
                        if (extArray) {
                            extension = extArray[1]
                        }
                        return 'icon' + (extension ? '.' + extension : '');
                    },
                    acceptedFiles: ["image/jpeg", "image/png"],
                    storeUrl: true
                }
            },
            disabled: !entityId && { disabledMessage: 'Please create organization before load files' }
        }),
        data_sources: {
            title: "Data sources",
            dataType: "array",
            description: "Data sources for the organization",
            of: {
                title: "Source",
                dataType: "map",
                properties: {
                    text: {
                        dataType: "string",
                        title: "Source description",
                    },
                    url: {
                        dataType: "string",
                        title: "Source URL",
                        config: { url: true },
                        validation: { url: true },
                    },
                },
            },
        },
        project_ids: {
            title: "Project IDs",
            dataType: "array",
            of: {
                title: "Project",
                dataType: "string"
            },
            disabled: {
                hidden: true
            }
        },
        featured: {
            title: "Featured",
            description: "Represent if this organization could appear in explore or not.",
            dataType:"boolean"
        },
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        }
    }
});
